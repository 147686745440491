.site-header-inner {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 10px !important;
}
.text-color-primary {
    color: #0094e1 !important;
}

.features-tiles-item-image {
    display: inline-flex;
    border-radius: 50%;
    background-color: #0094e1 !important;
}
.wrapper {
  width: 70%;
  margin: 0 auto;
  margin-bottom: 2em;
}

.wrapper input[type=text],
.wrapper input[type=email],
.wrapper textarea {
  box-sizing: border-box;
  width: 100%;
  margin-bottom: 15px;
  padding: 15px;
  border: 1px solid #0094e1;
  background: transparent;
  color: white;
  font-size: 20px;
}

.wrapper button {
  display: block;
  background-color: #134b6a;
  color: white;
  float: right;
  padding: 10px 15px;
  font-size: 20px;
  width: 120px;
  margin-right: 44px;
  border-radius: 11px;
  /* text-shadow: 0px 0px 15px #d6d6d6; */
}

@media (max-width: 768px) {
  .wrapper { width: 80%; }
}

@media (min-width: 1200px) {
  .wrapper { width: 60%; }
}
.wrapper input[type=text], .wrapper input[type=email], .wrapper textarea {
  box-sizing: border-box;
  width: 44%;
  margin: 10px;
  border-radius: 8px;
  margin-bottom: 15px;
  padding: 15px;
  border: 1px solid #0094e1;
  background: transparent;
  color: white;
  font-size: 20px;
}
@media (min-width: 641px)
{
.site-footer-inner {
    padding: 0px 0 !important;
}
}
img#tiles{
  height: 65px !important;
}